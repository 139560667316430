const Spacer = ({ height }) => {
    return (
        <div
            style={{
                width: '100%',
                height: height
            }}
        />
    )
}

export default Spacer